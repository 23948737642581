/* Base Layout */
.auto-section-1 {
  padding: 8px 13px 10px; /* top | horizontal | bottom */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auto-section-1-sub-1 {
  display: flex;
  align-items: center;
}

.auto-section-1-sub-1 label {
  font-size: 18px;
  font-family: "Gilroy-Bold";
  color: #000;
  margin: 0;
}

.auto-section-2 {
  margin: 10px 0;
}

.auto-section-3 {
  background: #fff;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 0.75rem;
  width: 100%;
  /* box-shadow: 0 1px 1px rgba(0,0,0,0.14); */
}

/* List Items */
.auto-section-3-toggle {
  background: #fff;
  padding: 15px 20px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.auto-section-23-sub-1 {
  flex: 1;
}

.auto-section-23-sub-1 h5 {
  font-size: 18px;
  color: #000;
  font-family: "Gilroy-Medium";
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 4px;
}

.auto-section-23-sub-1 p {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Gilroy-Bold";
  margin: 0;
}

/* Run Status */
.auto_label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-family: "Gilroy-SemiBold";
  color: #000;
  margin-top: 18px;
}

.auto_label img {
  height: 19px;
  margin-right: 10px;
}

.auto_label span {
  color: #6b7280;
  position: relative;
  left: 2em;
}

/* New Rule Button */
.auto_btn {
  background: #3a4b86;
  color: #fff;
  padding: 5px 15px;
  letter-spacing: 1px;
  border-radius: 10px;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
}

.auto_btn:hover {
  color: #fff;
}

/* Form Container */
.auto_rule_form {
  padding: 20px;
  background: #fff;
  margin: 10px 0;
}

/* When/Then Section */
.when_then {
  margin: 20px 0;
  padding: 24px;
  background: #f9fafb;
  border-radius: 8px;
  position: relative;
}

.when_then_label {
  font-weight: 600;
  font-size: 16px;
  color: #1f2937;
  margin-left: 10px;
  margin-bottom: 8px;
  font-family: "Gilroy-SemiBold";
  display: inline-block;
}

.when_then_trigger {
  display: block;
  color: #6b7280;
  margin: 8px 0 16px;
  font-size: 14px;
  margin-left: 44px;
  font-weight: 600;
  font-family: "Gilroy-Medium";
  margin-top: -5px;
}

/* Dot Indicators */
.auto_dot {
  height: 35px;
  width: 35px;
  background-color: #3faefdb2;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 12px;
}

.dot_no {
  padding: 5px 12px;
  color: #fff;
}

/* Select Components */
.auto_select {
  display: flex; /* Changed from -webkit-box to fix alignment */
  width: 100%;
  max-width: 400px; /* Restored from 300px */
  margin: 16px 0;
  align-items: center; /* Add this to align items */
}

.condition-group {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.condition-label {
  color: #6b7280;
  font-size: 14px;
  white-space: nowrap;
}

/* Style the condition select to match but be slightly smaller */
.condition-select {
  width: 200px;
  height: 38px;
  padding: 0 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #f9fafb;
  font-size: 14px;
}

.form-select {
  width: 100%;
  height: 38px;
  padding: 0 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #f9fafb;
  font-size: 14px;
  color: #374151;
  cursor: pointer;
  font-family: "Gilroy-Medium";
  /* Add better dropdown arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%236B7280'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  font-size: 13px;
  flex: 1;
  min-width: 0;
}
/* Add hover state */
.form-select:hover:not(:disabled) {
  border-color: #9ca3af;
}

/* Add focus state */
.form-select:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}
.form-select.disabled_input {
  background-color: #f3f4f6;
  cursor: not-allowed;
  color: #6b7280;
}
.form-select option {
  padding: 8px 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
}

/* AND Condition */
.and {
  font-weight: 500;
  color: #4b5563;
  margin-right: 8px;
}

.and_source {
  color: #6b7280;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 8px;
}

.and_select {
  min-width: 200px;
  flex: 1;
}
.gap-2 {
  gap: 0.5rem;
}

/* Action Section */
.auto_select_action {
  width: 100%; /* Use full width of card */
  margin: 8px 0; /* Remove left margin, use parent padding instead */
  padding: 0 8px;
}

.action-label {
  color: #6b7280; /* Slightly lighter gray */
  font-size: 11px; /* Reduced from 12px */
  margin-bottom: 2px; /* Reduced from 4px */
  font-family: "Gilroy-Medium";
}
.action-buttons svg[data-lucide="Settings2"],
.action-buttons svg[data-lucide="Trash2"] {
  color: #dc2626;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}
.action-buttons svg:hover {
  transform: scale(1.1);
}

.action-buttons svg[data-lucide="Settings2"]:hover,
.action-buttons svg[data-lucide="Trash2"]:hover {
  opacity: 1;
}

.action-row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px; /* Reduced gap between icons */
  margin-left: 12px;
  flex-shrink: 0;
}

.action-buttons svg[data-lucide="Settings2"] {
  color: #2196f3;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 4px;
  border-radius: 4px;
  background: rgba(33, 150, 243, 0.1); /* Light blue background */
}
.action-buttons svg[data-lucide="Settings2"]:hover {
  transform: scale(1.1);
  background: rgba(33, 150, 243, 0.2);
}

.action-buttons svg[data-lucide="Trash2"],
.day-header svg[data-lucide="Trash2"] {
  color: #ef4444;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 4px;
  border-radius: 4px;
  background: rgba(239, 68, 68, 0.1); /* Light red background */
}
.action-buttons svg[data-lucide="Trash2"]:hover,
.day-header svg[data-lucide="Trash2"]:hover {
  transform: scale(1.1);
  background: rgba(239, 68, 68, 0.2);
}
/* Add Action Button */
.add-action-button {
  width: 100%;
  margin: 12px 0 4px;
  padding: 8px;
  font-size: 13px;
  color: #2196f3;
  background: #ebf5ff;
  border-radius: 4px;
  font-family: "Gilroy-Medium";
  transition: all 0.2s ease;
  border: 1px dashed #2196f3;
}

.add-action-button:hover {
  background: #2196f3;
  color: white;
  border-style: solid;
}
.add-action-button svg {
  color: inherit;
  transition: transform 0.2s ease;
}
.add-action-button:hover svg {
  transform: rotate(90deg);
}
/* Parent container */
.delay-control-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

/* Main delay control */
.delay_plus_minus {
  display: inline-flex;
  align-items: center;
  background: #f8fafc;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 2px 4px;
  height: 28px;
}

/* Minus button */
.plush_minus_box_left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #64748b;
}

/* Plus button */
.plush_minus_box_right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #64748b;
}

/* Number input */
.plus_minus {
  width: 32px;
  height: 22px;
  text-align: center;
  font-size: 13px;
  border: 1px solid #e5e7eb;
  font-family: "Gilroy-Medium";
  border-radius: 3px;
  margin: 0 4px;
  padding: 0;
}

/* Remove spinner buttons from number input */
.plus_minus::-webkit-outer-spin-button,
.plus_minus::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Label text */
.delay_plus_minus span {
  color: #64748b;
  font-size: 13px;
  font-family: "Gilroy-Medium";
  margin-left: 4px;
  white-space: nowrap;
}

/* Hover states */
.plush_minus_box_left:hover,
.plush_minus_box_right:hover {
  color: #2196f3;
}

/* Focus state for input */
.plus_minus:focus {
  outline: none;
  border-color: #2196f3;
}

/* Error state */
.delay-error {
  color: #ef4444;
  font-size: 12px;
  font-family: "Gilroy-Medium";
  margin-top: 4px;
}

/* Disabled state */
.delay_plus_minus.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* Toggle & Loading States */
.rs-toggle {
  margin: 15px;
  float: right;
}

.disabled_input {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

/* Error Messages */
.error-message {
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
}

/* Day Section */
.day-section {
  width: 75%; /* Increased from 50% to use more screen width */
  margin: 0 auto 12px;
  background: white;
  border-radius: 6px;
  padding: 16px 20px; /* Slightly more horizontal padding */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}

.day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border-bottom: 1px solid #f3f4f6;
  padding-bottom: 10px;
}

.day-badge {
  background: #2196f3;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: "Gilroy-SemiBold";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

/* Drawer Styles */
.auto_action_from {
  width: 400px !important;
}

.action_lbl {
  font-size: 20px;
  font-family: "Gilroy-Bold";
}

.action_lbl span {
  display: block;
  font-size: 14px;
  line-height: 15px;
  font-family: "Gilroy-Bold";
  color: #75787a;
}

.rs-drawer-header-close {
  display: none;
}





/* Run Status Indicators */
.automation-run-status {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #6b7280;
}

.automation-run-count {
  color: #2196f3;
  font-weight: 500;
}

.automation-last-run {
  color: #6b7280;
}

/* List Item Animations */
.auto-section-3-toggle:hover {
  transform: translateY(-1px);
  transition: transform 0.2s ease;
}

/* Form Buttons */
.auto_form_btn {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.btn-primary {
  background: #3a4b86;
  color: #fff;
  padding: 10px 24px;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s;
  width: 420px;
  border-color: #3a4b86;
  font-family: "Gilroy-Bold";
  height: 48px;
  font-size: 20px;
}

.btn-primary:hover {
  background: #1976d2;
}

.btn-primary-save {
  background-color: #3a4b86;
  border-color: #3a4b86;
  font-family: "Gilroy-Bold";
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  padding: 9px 55px;
  height: 48px;
  border-radius: 0.75rem;
  width: 100%;
}

.btn-primary-save:hover {
  color: #fff;
}

.form-section {
  margin-bottom: 24px;
}

.form-group {
  position: relative;
}

.input-label {
  display: block;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #1f2937;
  margin-bottom: 8px;
}

.required-star {
  color: #ef4444;
  margin-left: 4px;
  font-size: 14px;
}

.form-control {
  width: 100%;
  height: 38px;
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #f9fafb;
  font-size: 14px;
  color: #374151;
  transition: border-color 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.form-control::placeholder {
  color: #9ca3af;
}

.form-control.disabled_input {
  background-color: #f3f4f6;
  cursor: not-allowed;
  color: #6b7280;
  border-color: #e5e7eb;
}

/* Preserve any existing error styles */
.error-text {
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
}
/* Add Day Button - Enhanced styling */
.add_new_plus {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  background: #f0f9ff; /* Light blue background */
  border: 1px dashed #2196f3;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 24px;
  width: 75%; /* Match day-section width */
  margin-left: auto;
  margin-right: auto;
}

.add_new_plus:hover {
  background: #e0f2fe;
  border-style: solid;
}

.auto_dot_plus {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #2196f3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.add_new_plus:hover .auto_dot_plus {
  transform: rotate(90deg);
}

.auto_dot_plus img {
  width: 14px;
  height: 14px;
  filter: brightness(0) invert(1); /* Make the plus icon white */
}

.add_new_plus label {
  color: #2196f3;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  font-family: "Gilroy-SemiBold";
}

.auto_action_from {
  width: 400px !important;
}

/* Add flexbox layout to drawer body */
.auto_action_from .rs-drawer-body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px); /* Account for header height */
}

/* Make the row take full height and use flexbox */
.auto_action_from .row {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

/* Content area should grow but allow button to stay at bottom */
.auto_action_from .form-group.action_select {
  flex: 1;
  overflow-y: auto;
}

/* Remove top margin and ensure button container is at bottom */
.auto_action_from .auto_action_form_btn {
  margin-top: 0;
  padding: 1rem;
  margin-top: auto;
}
.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
}

.data-table th {
  padding: 12px 16px;
  text-align: left;
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #64748b;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.data-table td {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  vertical-align: top;
  text-align: center; 
}

.name-cell {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.name-cell .primary {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #1a202c;
}

.name-cell .secondary {
  font-size: 13px;
  color: #64748b;
}

.name-cell .last-run {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #718096;
  margin-top: 4px;
}

.name-cell .last-run i {
  color: #eab308;
  font-size: 12px;
}

.metric-cell {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.metric-cell .value {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #1a202c;
}

.metric-cell .label {
  font-size: 12px;
  color: #718096;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.clickable-row:hover {
  background-color: #f8fafc;
}

.data-table td {
  max-width: 0; 
}


.data-table th:nth-child(1) { width: 25%; } 
.data-table th:nth-child(2) { width: 15%; } 
.data-table th:nth-child(3) { width: 10%; } 
/* Table Layout */
.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
  margin-top: 16px;
}

.data-table th {
  padding: 12px 16px;
  text-align: center;
  font-family: "Gilroy-SemiBold";
  font-size: 15px;
  color: #64748b;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.data-table td {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  vertical-align: middle;
  max-width: 0;
}

/* Row Interaction */
.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.clickable-row:hover {
  background-color: #f8fafc;
}

/* Cell Types */
.name-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 200px;
}

.name-cell .primary {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #1a202c;
}

.name-cell .secondary {
  font-size: 13px;
  color: #64748b;
}

.last-run-cell {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: #718096;
}

.last-run-cell i {
  color: #eab308;
  font-size: 12px;
}

.number-cell {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #1a202c;
  text-align: center !important; 
}

/* Text Utilities */
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

/* Column Widths */
.data-table th:nth-child(1) { width: 25%; }
.data-table th:nth-child(2) { width: 15%; }
.data-table th:nth-child(3),
.data-table th:nth-child(4),
.data-table th:nth-child(5),
.data-table th:nth-child(6) { width: 10%; }
.data-table th:nth-child(7) { width: 10%; }

/* Keep your existing header and button styles */
.auto-section-1 {
  padding: 10px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auto_btn {
  background: #3a4b86;
  color: #fff;
  padding: 5px 15px;
  letter-spacing: 1px;
  border-radius: 10px;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.auto_btn:hover {
  color: #fff;
  background: #2d3a68;
}

.auto-section-1 label {
  font-size: 24px;  
  font-family: "Gilroy-SemiBold";
  color: #1a202c;
}


.auto-section-1 label span {
  color: #64748b;
  font-size: 20px;
}
.auto-section-1-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.help-btn {
  padding: 6px 12px;
  color: #3a4b86;
  background: rgba(58, 75, 134, 0.1);
  border-radius: 6px;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.help-btn:hover {
  background: rgba(58, 75, 134, 0.15);
  color: #3a4b86;
  text-decoration: none;
}

.day-metrics {
  margin: 8px 0 12px;
  padding: 8px;
  background-color: #f5f8fa;
  border-radius: 4px;
}

.metrics-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
}

.metric {
  display: flex;
  align-items: center;
}

.metric.success {
  color: #2e7d32;
}

.metric.failed {
  color: #d32f2f;
}

.metric.total {
  color: #424242;
}

.metric-value {
  font-weight: 600;
  margin-right: 4px;
}

.metrics-progress {
  height: 4px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.progress-bar {
  height: 100%;
}

.progress-bar.success {
  background-color: #4caf50;
}

.progress-bar.failed {
  background-color: #f44336;
}

.day-header {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  position: relative;
}

.day-badge {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.spacer {
  flex-grow: 1;
}

.metric-cards {
  display: flex;
  gap: 6px;
  margin-right: 10px;
}

.metric-card {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 3px 8px;
  font-weight: 600;
  font-size: 15px;
  min-width: 40px;
  gap: 4px;
}

.metric-card.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

.metric-card.error {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.metric-card.total {
  background-color: #e3f2fd;
  color: #19d282;
  border: 1px solid #bbdefb;
}

.delete-day-button {
  color: #f44336;
  cursor: pointer;
}

/* Rule metrics styles for automation.css */
/* CSS that works with existing component structure */
.rule-metrics-summary {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
}

.rule-metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.metrics-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.last-run {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #666;
}

.last-run i {
  color: #ff9800;
  margin-right: 5px;
}

.metrics-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.metric-card {
  flex: 1 1 calc(20% - 12px);
  min-width: 160px;
  height: 52px;
  background: white;
  border-radius: 6px;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  position: relative;
}

/* Title positioning */
.metric-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* Value positioning */
.metric-value {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.metric-value i {
  display: none; /* Hide icons since they're in the screenshot */
}

/* Card styles for different types */
.metric-card.success {
  border-left: 4px solid #28a745;
}

.metric-card.success .metric-title {
  color: #28a745;
}

.metric-card.warning {
  border-left: 4px solid #fd7e14;
}

.metric-card.warning .metric-title {
  color: #fd7e14;
}

.metric-card.info {
  border-left: 4px solid #17a2b8;
}

.metric-card.info .metric-title {
  color: #17a2b8;
}

.metric-card.primary {
  border-left: 4px solid #007bff;
}

.metric-card.primary .metric-title {
  color: #007bff;
}

.metric-card.secondary {
  border-left: 4px solid #6c757d;
}

.metric-card.secondary .metric-title {
  color: #6c757d;
}

/* Responsive design */
@media (max-width: 992px) {
  .metric-card {
    flex: 1 1 calc(33.333% - 12px);
  }
}

@media (max-width: 768px) {
  .metric-card {
    flex: 1 1 calc(50% - 12px);
  }
}

@media (max-width: 576px) {
  .metrics-cards {
    gap: 10px;
  }
  
  .metric-card {
    flex: 1 1 100%;
    min-width: 0;
  }
}