// Quotation List Layout
.quotation-list-container {
  margin-left: 225px; // Space for sidebar
  margin-right: 0; // Remove unnecessary right margin
  padding: 0; // Remove unnecessary padding
  max-width: calc(100vw - 225px); // Simplified calculation
  
  &.full-page {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  .text-xl.font-semibold {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #1f2937;
    letter-spacing: -0.025em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    
    /* Add a subtle text shadow for depth */
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}

.quotation-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap; /* Prevent wrapping to new line */
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 12px; /* Consistent spacing between buttons */
  justify-content: flex-end; /* Align buttons to the right within their container */
}


// Sticky header styles
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

// Form container styles
.form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px 32px;
  
  .ant-form {
    background: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  }
}

// Main Content Layout
.quotation-list {
  &-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
  }

  &-content {
    padding: 16px; // Standardized padding
  }

  &-header {
    margin-bottom: 20px; // Adjusted for better proportion
  }
}

// Quotation Viewer Modal
.quotation-viewer {
  &-modal {
    .ant-modal-body {
      padding: 0;
      height: calc(100vh - 120px);
      overflow: hidden;
    }
  }
}

// Modal Components
.modal {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-body {
    flex: 1;
    padding: 16px 24px;
    overflow: auto;
  }

  &-footer {
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

// Status Management
.status-management-wrapper {
  margin: 0 0 8px 0;
  padding: 0;
  overflow: auto;
  display: flex;
  gap: 8px; // Reduced from 12px for better spacing

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 3px;
    
    &:hover {
      background: #b1b1b1;
    }
  }
}

.status-card {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  cursor: pointer;
  border-left: 4px solid transparent;
}

.status-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.status-card.active {
  border-left-width: 4px;
}

.status-card-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.status-card-amount {
  font-size: 20px;
  font-weight: 600;
}

.status-card-count {
  font-size: 13px;
  color: #6b7280;
}

// Product Components
.product-list {
  margin-bottom: 24px;
}

.product-input {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

// States and Containers
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
  text-align: center;
}

.iframe-container {
  position: relative;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

// Enhanced Table Styles
.enhanced-table {
  margin-top: 12px;
  
  .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 600;
    padding: 12px 16px; // Standardized padding
    white-space: nowrap; // Prevent header wrapping
  }

  .ant-table-tbody > tr > td {
    padding: 12px 16px; // Match header padding
    vertical-align: middle;
  }

  .ant-table-tbody > tr:hover > td {
    background: #f5f5f5;
  }
  
  // Fix pagination spacing
  .ant-pagination {
    margin: 16px 0 0 0;
  }
}

// Header Actions and Buttons


// Export button
.btn-export {
  color: #595959;
  border-color: #d9d9d9;
  height: 40px; // Consistent height
  padding: 0 16px;

  &:hover {
    color: #262626;
    border-color: #bfbfbf;
  }
}

// Filter button
.btn-filter {
  background: #f5f5f5;
  border: none;
  height: 40px; // Consistent height
  padding: 0 16px;

  &:hover {
    background: #e8e8e8;
  }
}

// Refresh button
.btn-refresh {
  color: #595959;
  height: 40px; // Consistent height
  padding: 0 16px;
  
  &:hover {
    color: #262626;
  }
}

// Create Quotation button
.btn-create {
  background: #1890ff;
  border: none;
  color: white;
  height: 40px; // Consistent height
  padding: 0 20px;

  &:hover {
    background: #096dd9;
  }
}

// Image Placeholder
.image-placeholder {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 4px;
}

// Fix search input styling
.quotation-search-input {
  .ant-input-group {
    display: flex;
  }

  .ant-input-group-addon {
    background-color: white;
  }

  .ant-input-search {
    .ant-input {
      height: 40px;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      font-size: 0.95rem;
      
      &:hover, &:focus {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        border-color: #1890ff;
      }
    }
    
    .ant-input-search-button {
      height: 40px;
      border-radius: 0 8px 8px 0;
      box-shadow: none;
    }
  }

  .ant-btn-default, .ant-dropdown-trigger {
    &[class*="date-filter"] {
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      background: #f9fafb;
      border: 1px solid #e5e7eb;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      
      &:hover {
        background: #f3f4f6;
        border-color: #d1d5db;
      }
      
      .anticon {
        font-size: 16px;
        margin-right: 6px;
        color: #4b5563;
      }
    }
  }

  .ant-input-affix-wrapper {
    border-right: 0;  // Remove double border
    border-radius: 6px 0 0 6px;  // Round only left corners
    height: 40px; // Consistent height
  }

  // Fix hover states
  .ant-input-affix-wrapper:hover + .ant-input-group-addon .ant-input-search-button {
    border-left-color: #1890ff;
  }
}

// Product search dropdown styling
.product-search-dropdown {
  width: 100%;
}

.product-search-dropdown .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1050; /* Ensure dropdown appears above other elements */
}

// Table action buttons
.text-gray-600.p-1 {
  padding: 6px !important; // More space for click target
  border-radius: 4px;
  
  svg {
    width: 18px;
    height: 18px;
  }
}

// Fix card padding to override inline styles
/* Refresh Button Styling */
.ant-btn {
  &:has(.anticon-reload) {
    height: 40px;
    border-radius: 8px;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
    color: #4b5563;
    font-weight: 500;
    transition: all 0.3s ease;
    
    &:hover {
      background: #f3f4f6;
      transform: translateY(-1px);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    
    .anticon {
      font-size: 16px;
    }
  }
}

/* Create Quotation Button Styling */
.ant-btn-primary {
  height: 40px;
  border-radius: 8px;
  background: #1890ff;
  border: none;
  box-shadow: 0 2px 5px rgba(24, 144, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 20px;
  font-weight: 500;
  transition: all 0.3s ease;
  
  &:hover {
    background: #096dd9;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(24, 144, 255, 0.3);
  }
  
  .anticon {
    font-size: 16px;
  }
}

/* Card Header Alignment */
.ant-card-body {
  .flex.justify-between.items-center.mb-6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-bottom: 16px !important;
    border-bottom: 1px solid #f0f0f0;
    flex-wrap: nowrap;
  }
}

// Responsive adjustments
@media (max-width: 1200px) {
  .status-card {
    min-width: 140px; // Even more compact on smaller screens
  }
}

@media (max-width: 992px) {
  .quotation-list-container {
    margin-left: 0; // Handle collapsed sidebar
    max-width: 100%;
  }
  
  .header-actions {
    flex-wrap: wrap;
  }
}

.quotation-created-info {
  display: flex;
  flex-direction: column;
}

.quotation-creator {
  display: flex;
  align-items: center;
  color: #4b5563;
}

.quotation-datetime {
  display: flex;
  align-items: center;
  color: #9ca3af;
  font-size: 12px;
  margin-top: 4px;
}

.quotation-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.quotation-table .ant-table-thead > tr > th {
  background-color: #f9fafb;
  font-weight: 500;
  color: #374151;
  padding: 12px 16px;
}

.quotation-table .ant-table-tbody > tr > td {
  padding: 12px 16px;
  vertical-align: middle;
}

.quotation-table .ant-table-tbody > tr:hover > td {
  background-color: #f9fafb;
}

.status-pill {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

.status-pill.created {
  background-color: #f5f5f5;
  color: #595959;
}

.status-pill.pending {
  background-color: #fff7e6;
  color: #d48806;
}

.status-pill.fully_paid {
  background-color: #e6f7ff;
  color: #096dd9;
}

.status-pill.partially_paid {
  background-color: #f6ffed;
  color: #389e0d;
}

.status-pill.overdue {
  background-color: #fff1f0;
  color: #cf1322;
}

.creator-info {
  display: flex;
  flex-direction: column;
}

.creator-name {
  font-weight: 500;
  color: #4b5563;
  display: flex;
  align-items: center;
  gap: 4px;
}

.creator-time {
  font-size: 12px;
  color: #9ca3af;
  margin-top: 2px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.action-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background: transparent;
  transition: all 0.2s;
  cursor: pointer;
}

.action-button:hover {
  transform: translateY(-2px);
}

.action-button.view {
  color: #1890ff;
}
.action-button.view:hover {
  background-color: #e6f7ff;
}

.action-button.edit {
  color: #52c41a;
}
.action-button.edit:hover {
  background-color: #f6ffed;
}

.action-button.delete {
  color: #ff4d4f;
}
.action-button.delete:hover {
  background-color: #fff1f0;
}

/* Amount Styling */
.amount-value {
  font-weight: 600;
  text-align: right;
  font-size: 15px;
  color: #1f2937;
}

/* Quotation ID Styling */
.quotation-id {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #1f2937;
}

@media (max-width: 1200px) {
  .quotation-list-container {
    .ant-input-search {
      max-width: 240px;
    }
  }
}

@media (max-width: 992px) {
  .quotation-list-container {
    .flex.justify-between.items-center.mb-6 {
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}