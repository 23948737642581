/* Backdrop styles */
.trigger-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050; /* Higher than sidebar */
  }
  
  /* Main popup container */
  .trigger-popup-content {
    background-color: white;
    border-radius: 8px;
    width: 800px;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    animation: popup-fade-in 0.2s ease-out;
  }
  
  @keyframes popup-fade-in {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Header styles */
  .trigger-popup-header {
    padding: 16px 20px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .trigger-popup-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #1a202c;
  }
  
  .trigger-popup-close {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .trigger-popup-close:hover {
    background-color: #f3f4f6;
    color: #1a202c;
  }
  
  /* Edit mode warning banner */
  .trigger-edit-warning {
    background-color: #fff8e6;
    border-bottom: 1px solid #ffeeba;
    padding: 8px 20px;
  }
  
  .trigger-edit-warning p {
    margin: 0;
    font-size: 14px;
    color: #856404;
  }
  
  /* Introduction text at the top of popup */
  .trigger-intro {
    padding: 12px 20px;
    background-color: #f8fafc;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .trigger-intro p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #4b5563;
  }
  
  /* Search container */
  .trigger-search-container {
    padding: 16px 20px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .trigger-search-input {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .trigger-search-icon {
    position: absolute;
    left: 12px;
    color: #9ca3af;
  }
  
  .trigger-search-input input {
    width: 100%;
    padding: 10px 36px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .trigger-search-input input:focus {
    outline: none;
    border-color: #3562CC;
    box-shadow: 0 0 0 2px rgba(53, 98, 204, 0.2);
  }
  
  .trigger-search-clear {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .trigger-search-clear:hover {
    color: #1a202c;
  }
  
  /* Body content */
  .trigger-popup-body {
    flex: 1;
    overflow-y: auto;
    padding: 12px 0;
  }
  
  /* Category sections */
  .trigger-category-section {
    margin-bottom: 24px;
  }
  
  .trigger-category-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b5563;
    margin: 0 20px 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  /* Trigger cards grid */
  .trigger-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 16px;
    padding: 0 20px;
  }
  
  /* Individual trigger card */
  .trigger-card {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    background-color: white;
    height: 100%;
  }
  
  .trigger-card:hover {
    border-color: #3562CC;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }
  
  .trigger-card.selected {
    border-color: #3562CC;
    background-color: rgba(53, 98, 204, 0.05);
  }
  
  .trigger-card.selected::after {
    content: "✓";
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #3562CC;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  
  .trigger-card.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .trigger-card-icon {
    margin-bottom: 12px;
    color: #3562CC;
  }
  
  .icon-placeholder {
    width: 40px;
    height: 40px;
    background-color: rgba(53, 98, 204, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #3562CC;
  }
  
  .trigger-card-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px;
    color: #1a202c;
  }
  
  .trigger-card-description {
    font-size: 12px;
    color: #6b7280;
    margin: 0;
    line-height: 1.5;
  }
  
  /* Empty state */
  .trigger-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
  }
  
  .trigger-empty-state p {
    color: #6b7280;
    margin-bottom: 16px;
  }
  
  .trigger-empty-state button {
    background-color: #f3f4f6;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    color: #4b5563;
    cursor: pointer;
  }
  
  .trigger-empty-state button:hover {
    background-color: #e5e7eb;
  }
  
  /* Footer */
  .trigger-popup-footer {
    padding: 16px 20px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .trigger-btn-secondary {
    background-color: white;
    border: 1px solid #d1d5db;
    color: #4b5563;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .trigger-btn-secondary:hover {
    background-color: #f9fafb;
    border-color: #9ca3af;
  }
  
  .trigger-btn-primary {
    background-color: #3562CC;
    border: 1px solid #3562CC;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .trigger-btn-primary:hover {
    background-color: #2c4fa6;
  }
  
  .trigger-btn-primary:disabled {
    background-color: #9ca3af;
    border-color: #9ca3af;
    cursor: not-allowed;
  }
  
  /* Trigger selector button styles for triggerSection.tsx */
  .trigger-selector-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
    min-height: 38px; /* Match form-select height */
  }
  
  .trigger-selector-button:hover {
    border-color: #9ca3af;
  }
  
  .trigger-selector-button.disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .trigger-selected-text {
    font-size: 14px;
    color: #1a202c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .trigger-selector-arrow {
    color: #9ca3af;
    font-size: 12px;
    margin-left: 8px;
  }
  
  /* Hide original select */
  .hidden-select {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }
  
  /* For mobile responsiveness */
  @media (max-width: 640px) {
    .trigger-grid {
      grid-template-columns: 1fr;
    }
    
    .trigger-popup-footer {
      flex-direction: column;
    }
    
    .trigger-btn-secondary,
    .trigger-btn-primary {
      width: 100%;
    }
  }