/* campaignView.css */
.campaign-view {
    min-height: 100vh;
    width: 100%;
    background: #f5f7f9;
}

.campaign-header {
    background: white;
    border-bottom: 1px solid #e8e8e8;
    padding: 8px 8px;  /* Reduced padding */
    min-height: 48px;   /* Set minimum height */
   
    margin-bottom: -1px;  
}

.header-content {
    display: flex;
    align-items: center;
    gap: 12px;         /* Reduced gap */
}

.campaign-info h1 {
    font-size: 18px;   /* Slightly smaller font */
    margin: 0;
    font-weight: 600;
    line-height: 1.2;  /* Tighter line height */
}

.campaign-meta {
    display: flex;
    align-items: center;
    gap: 6px;          /* Reduced gap */
    font-size: 12px;   /* Smaller font */
    color: #666;
    margin-top: 2px;   /* Reduced top margin */
}

.back-button {
    padding: 4px;      /* Reduced padding */
    height: 28px;      /* Fixed height */
    width: 28px;       /* Fixed width */
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-button:hover {
    background: #f0f0f0;
}

.campaign-info h1 {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
}



.campaign-content {
    padding: 16px;
}

.metric-card {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.04);
}

.table-controls {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 12px;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.metrics-grid {
    display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 12px !important;
   
    margin-bottom: 8px;
}

.metrics-grid-secondary {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  gap: 16px !important;
    margin-bottom: 16px; /* Add margin if needed */
  }

.metric-card .ant-card-body {
    padding: 8px 12px;
}

.metric-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.metric-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
}

.metric-value {
    font-size: 24px;
    font-weight: bold;
}

.metric-description {
    font-size: 13px;
    color: #666;
}

.leads-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #f0f0f0;
  }
  .leads-controls {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
.search-section {
    display: flex;
    align-items: center;
    gap: 16px;
}

.search-box {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  .search-box input {
    border: none;
    outline: none;
    margin-left: 8px;
    width: 200px;
  }

.export-button {
    background: #e6f4ff;
    color: #1890ff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.export-button:hover {
    background: #bae0ff;
}

.status-badge {
    display: inline-flex;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    color: white;
}

.action-buttons {
    display: flex;
    gap: 12px;
}

.action-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
}

.action-button:hover {
    background: #f0f0f0;
}
.leads-table-header h2 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
    font-weight: 600;
   
  }
  .ant-table-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .ant-table {
    flex: 1;
    border-radius: 12px;
  }
  
  /* Ensure table body scrolls properly */
  .ant-table-body {
    height: calc(100vh - 320px) !important; /* Add spaces around the minus */
    overflow-y: auto !important;
}
  
  /* Pagination styles */
  .ant-table-pagination {
    margin: 16px 0 !important;
    padding: 0 16px;
    background: white;
    border-top: 1px solid #f0f0f0;
    justify-content: center !important;
  }
  
  .ant-pagination-total-text {
    color: #666;
  }

  .info-message {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #201a40; /* Ant Design warning color */
    margin-left: auto; /* Push the message to the right */
  }

  /* Add these to your campaignView.css file */

/* Ensure metrics grids maintain their layout */
.campaign-view .metrics-grid,
.campaign-view .metrics-grid-secondary {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 12px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
}

/* Restore card styling */
.campaign-view .metric-card {
  border-radius: 12px !important;
  box-shadow: 0 2px 8px rgba(0,0,0,0.04) !important;
  padding: 0 !important;
  height: auto !important;
  min-width: auto !important;
  flex: 1 !important;
  background: white !important;
  border: none !important;
}

/* Fix internal card layout */
.campaign-view .metric-card .ant-card-body {
  padding: 8px 12px !important;
}

.campaign-view .metric-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 12px !important;
  position: static !important;
  transform: none !important;
}

.campaign-view .metric-title {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  font-weight: 600 !important;
  font-size: inherit !important;
  position: static !important;
  transform: none !important;
}

.campaign-view .metric-value {
  font-size: 24px !important;
  font-weight: bold !important;
  position: static !important;
  transform: none !important;
  right: auto !important;
  top: auto !important;
}

/* Fix table and badge styles */
.campaign-view .status-badge {
  display: inline-flex !important;
  padding: 4px 12px !important;
  border-radius: 12px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

/* Fix campaign header */
.campaign-view .campaign-header {
  background: white !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 8px 8px !important;
  min-height: 48px !important;
  margin-bottom: -1px !important;
}