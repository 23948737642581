// ProductForm.scss

// Custom Variables
$border-radius-sm: 4px;
$border-radius: 6px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$primary: #0d6efd;
$danger: #dc3545;
$white: #fff;

// Modal Overrides
.product-form-modal {
  .modal-dialog {
    max-width: 900px;
    margin: 1.5rem auto;
  }

  .modal-content {
    border: none;
    border-radius: $border-radius;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $gray-200;
  }

  .modal-body {
    padding: 1.5rem;
  }
}

// Image Upload Section
.dropzone-area {
  border: 2px dashed $gray-300;
  border-radius: $border-radius;
  padding: 1.5rem;
  text-align: center;
  background: $gray-100;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover, &.active {
    border-color: $primary;
    background: $gray-200;

    .upload-icon {
      transform: scale(1.1);
    }
  }

  .upload-icon {
    color: $gray-600;
    margin-bottom: 0.75rem;
    transition: transform 0.2s ease;
  }

  .upload-text {
    color: $gray-600;
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }

  .file-info {
    color: $gray-500;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
}

// Preview Grid
.preview-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  margin-top: 1rem;

  .preview-item {
    position: relative;
    aspect-ratio: 1;
    border-radius: $border-radius-sm;
    overflow: hidden;
    border: 1px solid $gray-300;
    background: $gray-100;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.2s ease;
    }

    .remove-btn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      border-radius: $border-radius-sm;
      background: rgba($white, 0.9);
      color: $danger;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.2s ease;

      &:hover {
        background: $white;
        transform: scale(1.1);
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
      .remove-btn {
        opacity: 1;
      }
    }
  }
}

// Form Field Styles
.field-row {
  margin-bottom: 1rem;
}

.field-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  .field-icon {
    color: $gray-600;
  }

  .required {
    color: $danger;
    margin-left: 0.25rem;
  }
}

// Price Input
.price-input-wrapper {
  position: relative;

  .currency-symbol {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-600;
  }

  .form-control {
    padding-left: 2rem;
  }
}

// Price Calculation Display
.price-calculation {
  margin-top: 0.5rem;
  padding: 0.75rem;
  background: $gray-100;
  border: 1px solid $gray-300;
  border-radius: $border-radius-sm;
  font-size: 0.875rem;

  .price-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .label {
      color: $gray-600;
    }

    .value {
      font-weight: 500;
    }

    & + .price-item {
      margin-top: 0.5rem;
    }
  }
}

// Error States
.error-message {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  margin-top: 0.35rem;
  color: $danger;
  font-size: 0.75rem;
}

.form-control, .form-select {
  &.error {
    border-color: $danger;
    background-color: rgba($danger, 0.05);
    
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
    }
  }
}

// Loading Animation
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid $white;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

// Form Actions
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 1.25rem;
  margin-top: 1rem;
  border-top: 1px solid $gray-200;
}

// Media Queries
@media (max-width: 768px) {
  .product-form-modal .modal-dialog {
    margin: 1rem;
    max-width: calc(100% - 2rem);
  }

  .preview-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .preview-grid {
    grid-template-columns: 1fr;
  }

  .form-actions {
    flex-direction: column;
    gap: 0.5rem;

    .btn {
      width: 100%;
    }
  }
}