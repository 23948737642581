.activity-item {
  position: relative;
  display: flex;
  padding: 8px 12px;
  margin-bottom: 4px;
  gap: 12px;
}

.activity-line {
  position: absolute;
  left: 32px;
  top: 40px;
  bottom: -12px;
  width: 2px;
  background-color: #e5e7eb;
}

.activity-item:last-child .activity-line {
  display: none;
}

.activity-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
}

.activity-item {
  position: relative;
  display: flex;
  padding: 8px 12px;
  margin-bottom: 4px;
  gap: 12px;
  align-items: flex-start;
}

.activity-line {
  position: absolute;
  left: 27px;
  top: 36px;
  bottom: -12px;
  width: 2px;
  background-color: #e5e7eb;
}

.activity-content {
  flex: 1;
  min-width: 0;
  padding-top: 2px;
}

.activity-description {
  font-size: 14px;
  line-height: 1.4;
  color: #111827;
}

.note-content {
  margin-top: 4px;
  font-size: 13px;
  color: #4b5563;
  background-color: #f3f4f6;
  padding: 6px 8px;
  border-radius: 4px;
}

/* Specific background colors are now handled in the component */

.activity-item {
  position: relative;
  display: flex;
  padding: 8px 12px;
  margin-bottom: 4px;
  gap: 12px;
  align-items: flex-start;
}

.activity-line {
  position: absolute;
  left: 29px;
  top: 40px;
  bottom: -12px;
  width: 2px;
  background-color: #e5e7eb;
}

.activity-content {
  flex: 1;
  min-width: 0;
  margin-top: 4px;
}

.activity-description {
  font-size: 14px;
  line-height: 1.4;
  color: #111827;
}

.activity-time {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 2px;
}

.note-content {
  margin-top: 4px;
  font-size: 13px;
  color: #4b5563;
  background-color: #f3f4f6;
  padding: 6px 8px;
  border-radius: 4px;
}

.activity-item .creator-info {
  display: flex;
  flex-direction: row; /* Override the column direction */
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 8px;
}

.creator-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e5e7eb;
  display: flex;
  align-items: left;
  justify-content: center;
  font-size: 10px;
  color: #4b5563;
  text-transform: uppercase;
}

.creator-name {
  font-size: 12px;
  color: #6b7280;
}

/* WhatsApp specific styling */
.activity-icon[data-type*="whatsapp"] {
  background-color: #25D366 !important;
}

.activity-icon[data-type*="whatsapp_automation_message_read"] {
  background-color: #25D366 !important;
}

.activity-icon[data-type*="whatsapp"] .read-receipt {
  color: #34B7F1;
}

/* Call specific styling */
.activity-icon[data-type*="call"] {
  background-color: #0284C7 !important;
}

.activity-icon[data-type*="missed_call"] {
  background-color: #EF4444 !important;
}

/* Status update styling */
.activity-icon[data-type="status_updated"] {
  background-color: #F59E0B !important;
}

/* Task styling */
.activity-icon[data-type*="task"] {
  background-color: #8B5CF6 !important;
}

.activity-icon[data-type="task_completed"] {
  background-color: #10B981 !important;
}

/* Note styling */
.activity-icon[data-type="note_created"] {
  background-color: #6366F1 !important;
}

/* Reply styling */
.activity-icon[data-type*="replied"] {
  background-color: #3B82F6 !important;
}

.activity-content {
  flex: 1;
  min-width: 0;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.activity-time {
  font-size: 12px;
  color: #6b7280;
}

.edit-button {
  padding: 4px;
  border-radius: 4px;
  color: #6b7280;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.activity-item:hover .edit-button {
  opacity: 1;
}

.activity-description {
  font-size: 14px;
  line-height: 1.4;
}

.activity-label {
  color: #111827;
  margin-bottom: 4px;
}

.note-content {
  background-color: #f3f4f6;
  padding: 8px;
  border-radius: 4px;
  margin-top: 4px;
  font-size: 13px;
  color: #4b5563;
}

.extra-details {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 4px;
  font-size: 12px;
  color: #6b7280;
}

.template-info, .campaign-info {
  background-color: #e5e7eb;
  padding: 2px 6px;
  border-radius: 4px;
}

.due-date {
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
}

.creator-info {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
}

.creator-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #4b5563;
  text-transform: uppercase;
}

.creator-name {
  font-size: 12px;
  color: #6b7280;
}

.bg-activity-purple {
  background-color: #f5f3ff;
}

.bg-activity-blue {
  background-color: #eff6ff;
}