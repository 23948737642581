.product-picker-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 75vh; /* Reduced from 80vh to make room for pagination */
}
  
  .product-picker-layout {
    display: flex;
    height: 100%;
    gap: 16px;
  }
  
  .product-catalog-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    min-width: 0;
  }
  
  .selected-products-panel {
    flex: 1;
    border-left: 1px solid #eee;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 75vh;
  }
  
  .product-picker-search {
    padding: 0 0 16px 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }
  
  .search-input {
    font-size: 16px;
    height: 46px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .clear-button {
    color: #999;
  }
  
  .product-picker-content {
    flex: 1;
    overflow-y: auto;
    padding: 0 0 16px 0;
    scroll-behavior: smooth;
    max-height: calc(65vh - 120px); /* Explicitly set max height to leave room for pagination */
  }
  
  
  .product-grid {
    width: 100%;
  }
  
  .product-card {
    cursor: pointer;
    transition: all 0.2s;
    border: 2px solid transparent;
    height: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .product-card.selected {
    border-color: #1890ff;
    background-color: #f0f7ff;
  }
  
  .product-card.selected::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 24px 24px 0;
    border-color: transparent #1890ff transparent transparent;
  }
  
  .product-image {
    position: relative;
    height: 150px;
    margin-bottom: 0;
    overflow: hidden;
    background: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover .product-image img {
    transform: scale(1.05);
  }
  
  .image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8392ab;
    background: #f8f9fa;
  }
  
  .product-checkbox {
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  
  .product-details {
    padding: 0 4px;
  }
  
  .product-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
    color: #1e293b;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
  
  .product-category {
    margin-bottom: 8px;
    background: #f1f5f9 !important;
    color: #64748b !important;
    border: none !important;
  }
  
  .product-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #eee;
    background: #f7f9fc;
    border-radius: 0 0 8px 8px;
  }
  
  .product-picker-totals {
    display: flex;
    flex-direction: column;
  }
  
  .total-products, .total-amount {
    display: flex;
    gap: 8px;
  }
  
  .total-products .count {
    font-weight: 500;
  }
  
  .total-amount .amount {
    font-weight: 600;
    font-size: 16px;
    color: #0284c7;
  }
  
  .product-picker-actions {
    display: flex;
    gap: 12px;
  }
  
  .confirm-button {
    min-width: 220px;
    height: 40px;
    font-weight: 500;
  }
  
  .product-picker-pagination {
    display: flex;
    justify-content: space-between; /* Changed from center to space-between */
    align-items: center;
    margin-top: 16px;
    padding: 16px 0;
    border-top: 1px solid #eee;
    background: white;
    position: sticky;
    bottom: 0;
    z-index: 10; /* Increased z-index */
    width: 100%;
  }
  
  .pagination-controls {
    margin: 0;
  }
  

  .product-unit {
    font-size: 12px;
    color: #64748b;
    font-weight: normal;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    background: white;
    border-radius: 4px;
    padding: 4px;
    border: 1px solid #e5e7eb;
  }
  
  .quantity-controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    background-color: #f7f7f7;
    border: 1px solid #e0e0e0;
    transition: background-color 0.2s;
  }
  
  .quantity-controls button:hover:not(:disabled) {
    background-color: #e6f7ff;
  }
  
  .quantity-controls button:disabled {
    opacity: 0.5;
  }
  
  .quantity-value {
    flex: 1;
    text-align: center;
    font-weight: 500;
    min-width: 24px;
  }
  
  /* Custom styles for the PopupComponent */
  :global(.popup-content.popup-lg) {
    width: 90vw !important;
    max-width: 1200px !important;
    max-height: 85vh !important;
  }
  
  :global(.popup-header) {
    background-color: #f0f2f5;
    padding: 16px 20px !important;
  }
  
  :global(.popup-body) {
    padding: 0 !important;
  }
  
  @media (max-width: 768px) {
    .product-picker-layout {
      flex-direction: column;
    }
    
    .selected-products-panel {
      border-left: none;
      border-top: 1px solid #eee;
      padding-left: 0;
      padding-top: 16px;
      max-height: none;
    }
  }
  
  .selected-products-section {
    margin-top: 0;
  }
  
  .selected-header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
  }
  
  .selected-products-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .selected-product-item {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 6px;
    border: 1px solid #eee;
    gap: 8px;
  }
  
  .selected-product-info {
    flex: 1;
    min-width: 0;
  }
  
  .selected-product-name {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
  
  .selected-product-price {
    font-size: 12px;
    color: #666;
  }
  
  .selected-product-quantity {
    display: flex;
    align-items: center;
    gap: 4px;
    min-width: 88px;
  }
  
  .selected-product-total {
    font-weight: 500;
    color: #0284c7;
    min-width: 70px;
    text-align: right;
  }
  
  .selected-product-remove {
    color: #ff4d4f;
  }
  
  .empty-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .help-text {
    color: #8c8c8c;
    margin-top: 8px;
  }
  
  .product-picker-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* Add these styles to your PopupComponent.css file */

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: fadeIn 0.2s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .popup-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    animation: slideUp 0.2s ease-out;
    overflow: hidden;
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0.8; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Sizes */
  .popup-sm { width: 400px; }
  .popup-md { width: 600px; }
  .popup-lg { width: 90vw; max-width: 1200px; }
  
  .popup-header {
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f2f5;
  }
  
  .popup-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  
  .popup-close {
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  
  .popup-close:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .popup-body {
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .popup-footer {
    padding: 16px 20px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    background-color: #f7f9fc;
  }
  
  .popup-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #1890ff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .popup-sm, .popup-md, .popup-lg {
      width: 95vw;
      max-width: none;
    }
    
    .popup-content {
      max-height: 95vh;
    }
  }