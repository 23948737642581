.automation-rule-wrapper {
    background-color: #fff;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: -16px; /* Reduce distance from top */
  }
  
  .automation-rule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #f8fafc;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .automation-rule-header h2 {
    margin: 0;
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 20px;
    color: #1a202c;
  }
  
  .cancel-button {
    padding: 6px 16px;
    border-radius: 6px;
    color: #fff;
    background-color: #ef4444;
    border: none;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .cancel-button:hover {
    background-color: #dc2626;
  }
  
  .delete-button {
    padding: 6px 12px;
    border-radius: 6px;
    color: #ef4444;
    background-color: rgba(239, 68, 68, 0.1);
    border: 1px solid rgba(239, 68, 68, 0.2);
    font-size: 14px;
    transition: all 0.2s;
  }
  
  .delete-button:hover {
    background-color: rgba(239, 68, 68, 0.2);
  }
  
  .automation-rule-content {
    flex: 1;
    overflow-y: auto;
    padding: 0;
    background-color: #f9fafb;
  }
  
  /* Override some existing styles for better integration */
  .automation-rule-content .row.auto_rule_form {
    margin: 0;
    padding: 0;
  }
  
  /* Make sure the drawer content takes up full height */
  .automation-rule-content .auto_rule_form {
    height: auto;
    overflow-y: visible;
    background-color: white;
    border-radius: 8px;
    margin: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Ensure proper button spacing */
  .automation-rule-content .auto_form_btn {
    margin-bottom: 32px;
    margin-top: 24px;
  }
  
  /* Improve form section spacing */
  .automation-rule-content .form-section {
    margin-bottom: 20px;
  }
  
  /* Add some visual separation between sections */
  .automation-rule-content .when_then {
    margin-top: 12px;
  }
  
  /* Make drawer children containers respect our wrapper */
  .automation-rule-content .rs-drawer-body {
    max-height: none;
    overflow: visible;
  }
  
  .automation-rule-content .automatic_rule_drawer {
    position: static;
    overflow: visible;
  }