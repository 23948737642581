// Invoice Form Layout
.invoice-layout {
  min-height: 100vh;
  background-color: #f8fafc;
}

// Header Styles
.invoice-header {
  height: 72px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  font-family: 'Gilroy', sans-serif;
  position: sticky;
  top: 0;
  z-index: 100;
  
  .header-back-btn {
    font-size: 14px;
    height: 36px;
    border-color: #e5e7eb;
    
    &:hover {
      border-color: #d1d5db;
      background: #f9fafb;
    }
  }

  .header-title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .header-actions {
    .btn {
      height: 40px;
      padding: 0 18px;
      min-width: 110px;
      font-weight: 500;
    }
  }
}

.container-xxl {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  gap: 16px;
}

// Main Content Container
.invoice-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1.75rem;
}

// Form Card Styles
.invoice-card {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  margin-bottom: 1.75rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

  .card-body {
    padding: 1.5rem;
  }

  .card-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  
  .product-search-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    
    input {
      border-radius: 6px;
      padding: 10px 12px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    }
  }

  .products-list-container {
    margin-top: 1.25rem;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    overflow: hidden;
  }
}

// Sticky Summary Section
.invoice-summary-sticky {
  position: sticky;
  top: 84px;
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0;
    
    .label {
      font-size: 14px;
      color: #4b5563;
    }
    
    .value {
      font-weight: 500;
      color: #111827;
    }
  }
  
  .total-amount {
    font-size: 18px;
    font-weight: 600;
    color: #2563eb;
  }
}

// Product Search Section
.product-search-container {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  
  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1050;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0;
  }
  
  .dropdown-item {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #f3f4f6;
    
    &:last-child {
      border-bottom: none;
    }
    
    &:hover {
      background-color: #f9fafb;
    }
  }
}

// Form Controls
.amount-input {
  width: 100%;
  border-radius: 6px;
  
  &.currency-input {
    padding-left: 1.5rem;
  }
}

.notes-textarea {
  font-size: 14px;
  min-height: 120px;
  resize: vertical;
  border-radius: 6px;
  border-color: #e5e7eb;
  
  &:focus {
    border-color: #93c5fd;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
}

// Adjustments Section
.adjustments-section {
  .section-title {
    font-size: 14px;
    color: #4b5563;
    margin-bottom: 0.75rem;
    font-weight: 500;
  }
  
  .action-link {
    font-size: 14px;
    padding: 0;
    color: #2563eb;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    
    &:hover {
      color: #1d4ed8;
      text-decoration: underline;
    }
  }
  
  .section-divider {
    border-top: 1px solid #e5e7eb;
    padding-top: 1.25rem;
    margin-top: 1.25rem;
  }
}

// Button Styles
.invoice-action-btn {
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  
  &.with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

// Responsive Adjustments
@media (max-width: 992px) {
  .invoice-summary-sticky {
    position: static;
    margin-bottom: 2rem;
  }
  
  .invoice-container {
    padding: 1rem;
  }
}

// Button styles for header
.btn-outline-secondary {
  height: 38px;
  padding: 0 16px;
  border: 1px solid #e5e7eb;
  background: #f9fafb;
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    border-color: #cbd5e1;
    color: #1e293b;
  }
  
  .lucide {
    width: 16px;
    height: 16px;
  }
}

.btn-primary {
  background: #2563eb;
  color: white;
  border: none;
  font-weight: 500;
  
  &:hover {
    background: #1d4ed8;
  }
}

// Product search dropdown specific styles
.product-search-dropdown {
  width: 100%;
  
  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1050;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
}

.fs-5 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Gilroy', sans-serif;
  color: #0f172a;
}

.d-flex.gap-2 {
  display: flex;
  align-items: center;
  gap: 12px !important;

  .btn {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Gilroy', sans-serif;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &.btn-light {
      background: #ffffff;
      border: 1px solid #fee2e2;
      color: #ef4444;
      padding: 0 20px;

      &:hover {
        background: #fef2f2;
        border-color: #fecaca;
      }
    }

    &.btn-primary {
      background: #2563eb;
      border: none;
      color: #ffffff;
      padding: 0 24px;
      min-width: 140px;

      &:hover {
        background: #1d4ed8;
      }
    }
  }
}

/* Force the DatePicker popup to render at document level with highest z-index */
.react-datepicker-popper {
  z-index: 9999 !important;
  position: fixed !important;
}

/* Ensure the input field doesn't have overflow hidden ancestors */
.react-datepicker__input-container .ant-input {
  position: relative;
  z-index: 1;
}

/* Full-width DatePicker container */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

/* Fix for calendar position */
.react-datepicker {
  font-family: inherit;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}